






















import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

@Component({ name: 'DepositOption' })
export default class DepositOption extends Vue {
  @PropSync('option_name', { type: String, required: true })
  synced_option_name!: string;

  @PropSync('icon_src', { type: String, required: true })
  synced_icon_src!: string;

  @PropSync('icon_active_src', { type: String, required: true })
  synced_icon_active_src!: string;

  @PropSync('title', { type: String, required: true })
  synced_title!: string;

  @PropSync('description', { type: String, required: true })
  synced_description!: string;

  @PropSync('is_active', { type: Boolean, required: true })
  synced_is_active!: boolean;

  setActive() {
    this.$emit('setActiveOption', this.synced_option_name);
  }
}

